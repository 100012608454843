import { clsx } from "clsx";
import BgImage from "./BgImage";
import InView from "./InView";
import SmartLink from "./SmartLink";

const logo = "https://cdn.trustpilot.net/brand-assets/4.3.0/logo-black.svg";
const stars =
  "https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-4.5.svg";

type Props = { className?: string };

const Trustpilot: React.FC<Props> = (props) => {
  return (
    <InView.Self>
      {({ inView }) => (
        <SmartLink
          to="https://uk.trustpilot.com/review/prmf.co.uk"
          className={clsx(
            "mx-auto block w-full max-w-[280px] space-y-2",
            "rounded-t-lg bg-white p-4 px-6",
            "origin-bottom scale-75 transition",
            "hover:translate-y-0",
            "[&_iframe]:border-0",
            inView ? "delay-200" : "translate-y-full",
            props.className,
          )}
        >
          <BgImage src={logo} className="h-8 bg-white !bg-contain bg-left" />
          <div className="flex items-center gap-2">
            <BgImage src={stars} className="h-9 bg-white !bg-contain" />
            <span className="text-lg font-semibold">4.7</span>
          </div>
        </SmartLink>
      )}
    </InView.Self>
  );
};

export default Trustpilot;
